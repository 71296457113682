/*** Main Styling ***/
:root {
    --font-h1: 3.5rem;
    --font-h1-sub: 1.5rem;
    --font-h2: 2.5rem;
    --font-h2-sub: 1.3rem;
    --font-h3: 2rem;
    --font-h4: 1.5rem;
    --font-h5: 1rem;
    --font-txl: 3rem;
    --font-tl: 1.3rem;
    --font-ts: 1rem;
    --font-cta: 1.3rem;
    --dark-gray: #585858;
    --almost-black: #040404;
    --red-orange: #F5755D;
    --yellow: #F4DE57
}

@media (max-width: 992px) {
    :root {
        --font-h1: 2.5rem;
        --font-h1-sub: 1.3rem;
        --font-h2: 2rem;
        --font-h2-sub: 1.1rem;
        --font-h3: 1.5rem;
        --font-h4: 1.2rem;
        --font-h5: 0.8rem;
        --font-txl: 2rem;
        --font-tl: 1.1rem;
        --font-ts: 0.8rem;
        --font-cta: 1.1rem;
    }
}

@media (max-width: 768px) {
    :root {
        --font-h1: 2rem;
        --font-h1-sub: 1.2rem;
        --font-h2: 1.8rem;
        --font-h2-sub: 1rem;
        --font-h3: 1.3rem;
        --font-h4: 1rem;
        --font-h5: 0.7rem;
        --font-txl: 1.8rem;
        --font-tl: 1rem;
        --font-ts: 0.7rem;
        --font-cta: 1rem;
    }
}

@media (max-width: 576px) {
    :root {
        --font-h1: 1.5rem;
        --font-h1-sub: 1rem;
        --font-h2: 1.5rem;
        --font-h2-sub: 0.9rem;
        --font-h3: 1.1rem;
        --font-h4: 0.9rem;
        --font-h5: 0.6rem;
        --font-txl: 1.5rem;
        --font-tl: 0.9rem;
        --font-ts: 0.6rem;
        --font-cta: 0.9rem;
    }
}

@font-face {
    font-family: Font;
    src: url(/fonts/ProximaNova_Regular.ttf);
    font-display: swap;
    font-weight: 400;
}

@font-face {
    font-family: Font;
    src: url(/fonts/ProximaNova_Light.ttf);
    font-display: swap;
    font-weight: 300;
}

@font-face {
    font-family: Font;
    src: url(/fonts/ProximaNova_Semibold.ttf);
    font-display: swap;
    font-weight: 700;
}

hr {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

body #root {
    font-family: Font;
}

/* free report */
.custom-alphabetic-list li {
    margin: 10px 0;
}

.custom-alphabetic-list li::marker {
    font-weight: bold;
}

.bg-yellow {
    background-color: var(--yellow);
}

.free-report-header img {
    max-width: 250px;
}

.watermark {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-10deg);
    font-size: 1.8rem;
    /* Base font size */
    font-weight: 700;
    color: rgba(0, 0, 0, 0.2);
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .watermark {
        top: 15%;
        /* Adjust top position */
        font-size: 2.2rem;
        /* Increase font size */
        white-space: nowrap;
    }
}

/* Large devices (desktops, 1024px and up) */
@media (min-width: 1024px) {
    .watermark {
        top: 10%;
        /* Further adjust top position */
        font-size: 2rem;
        /* Further increase font size */
        white-space: nowrap;
    }
}

/* Extra large devices (large desktops, 1440px and up) */
@media (min-width: 1440px) {
    .watermark {
        top: 8%;
        /* Position slightly higher */
        font-size: 2.5rem;
        /* Larger font size for big screens */
    }
}

/* free report */
/* new landing page style started*/
.font-bold {
    font-weight: bold;
}

.font-weight-900 {
    font-weight: 900;
}

.h1 {
    font-size: var(--font-h1);
    font-weight: bold;
}

.h1-sub {
    font-size: var(--font-h1-sub);
}

.h2 {
    font-size: var(--font-h2);
    font-weight: bold;
}

.h2-sub {
    font-size: var(--font-h2-sub);
}

.h3 {
    font-size: var(--font-h3);
    font-weight: bold;
}

.h4 {
    font-size: var(--font-h4);
    font-weight: bold;
}

.h5 {
    font-size: var(--font-h5);
    font-weight: bold;
}

.text-xl {
    font-size: var(--font-txl);
}

.text-l {
    font-size: var(--font-tl);
}

.text-s {
    font-size: var(--font-ts);
}

.cta {
    font-size: var(--font-cta);
}

.text-left {
    text-align: left;
}

.text-dark-gray {
    color: var(--dark-gray);
}

.bg-dark-gray {
    background-color: var(--dark-gray);
}

.bg-red-orange {
    background-color: var(--red-orange);
}

.border-10px {
    border-radius: 10px;
}

.border-12px {
    border-radius: 12px;
}

.absolute-circle {
    position: absolute;
    top: -45px;
    right: 16px;
    width: 64px;
    height: 64px;
    background-color: #F5755D;
    border-radius: 50%;
}

.w-90 {
    width: 90%;
}

.font-2-rem {
    font-size: 2.1rem;
}

.font-20px {
    font-size: 20px;
}

.font-18px {
    font-size: 18px !important;
}

.navbar {
    z-index: 50;
}

.img-fit {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.result-bottom-logo {
    width: 40%;
}

.img-fit-right {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right;
}

.custom-button {
    font-weight: 900;
    padding: 10px 25px;
    border-radius: 7px;
}


.badge {
    background-color: #F5755D;
    position: absolute;
    left: 10%;
    top: -40px;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.badge-right {
    background-color: #F5755D;
    position: absolute;
    right: 5%;
    top: -70%;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.margin-bottom-70px {
    margin-bottom: 70px;
}

.badge>img {
    width: 68px;
    height: 68px;
}

.badge-right>img {
    width: 48px;
    height: 48px;
}

.download-card {
    border-radius: 12px;
    padding: 0 12px;
}


.icon-font {
    font-family: "Baskerville-Bold", serif;
    font-size: clamp(2rem, 10vw, 4.5rem);
    font-weight: 900;
    color: #585858;
}

.custom-list::before {
    content: '';
    display: inline-block;
    margin-right: 8px;
    background-color: #585858;
    border-radius: 50%;
    width: 12px;
    height: 12px;
}

.custom-list {
    text-indent: -20px;
    list-style-type: none;
    padding-left: 20px;
    margin-bottom: 12px;
    color: var(--almost-black);
}

.price-card {
    border-radius: 10px;
    transition: transform 0.3s ease;
    overflow: hidden;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
}


.row:hover .active-card {
    transform: none;
    z-index: 0;
}

.price-card:hover .card-header,
.active-card-header {
    background-color: #F4DE58;
}

.price-card:hover .card-header {
    background-color: #F4DE58;
}

.card:hover {
    border-bottom: 0 solid #fcbf1e;
}

.card-header {
    border-bottom: none;
    z-index: 0;
    border-radius: 0 0 12px 12px !important;
    -webkit-box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.2);
}

.expert-image {
    width: 120px;
    height: 120px;
}

.expert-logo {
    width: 280px;
    height: 110px;
    object-fit: contain;
}

.expert-card {
    border-radius: 12px;
}

/* Extra small devices (phones) */
@media (max-width: 575.98px) {
    .icon {
        width: 20px;
        height: 20px;
    }

    .simpleNavLogo {
        display: flex;
        justify-content: center;
    }
}

/* Small devices (tablets) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .icon {
        width: 25px;
        height: 25px;
    }

    .simpleNavLogo {
        display: flex;
        justify-content: center;
    }
}

/* Medium devices (desktops) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .icon {
        width: 27px;
        height: 27px;
    }

    .price-card:hover,
    .active-card {
        transform: scale(1.09);
        z-index: 10;
    }

}

/* Large devices (large desktops) */
@media (min-width: 992px) {

    .icon {
        width: 27px;
        height: 27px;
    }

    .price-card:hover,
    .active-card {
        transform: scale(1.1);
        z-index: 10;
    }
}

@media (min-width: 1500px) {
    .simpleNavLogo {
        position: absolute;
        top: 0;
    }

    .padding-xl {
        padding-top: 86px;
    }
}

/* new landing page style ended */

.img-responsive {
    max-width: 100%;
}

.alignright {
    text-align: right;
}

.floatright {
    float: right;
}

.margintop {
    margin-top: 15px;
}

.marginright {
    margin-right: 40px;
}

.link {
    padding: 0 5px;
    color: #f5755d;
    cursor: pointer;
}

.align_mid {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.relative {
    position: relative;
}

.closeInfo {
    cursor: pointer;
}

.fa-info-circle {
    cursor: pointer;
}

.padding32 {
    padding: 32px !important;
}

.noscroll {
    position: fixed;
    left: 0;
    right: 0;
    overflow: hidden;
    height: 100vh;
}

input[type="radio"] {
    width: 20px;
    height: 20px;
    margin: 20px;
}

input[type="number"] {
    border-radius: 20px;
    border: 1px solid #cecece;
}

.form-check.hidden {
    display: none;
}

.option {
    margin-bottom: 20px;
    cursor: pointer;
}

.option.no-effect {
    cursor: initial;
}

#skipbtn {
    margin-bottom: 40px;
    background: none;
    border: 2px solid #f5755d;
    color: #F5755D;
}

#submitbtn {
    margin-bottom: 40px;
    margin-left: 10px;
}

.button-row {
    margin-bottom: 40px;
}

button.back,
#moreInfo,
.btn-link {
    color: #f5755d;
    padding: 0;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    white-space: normal;
}

.sub-title {
    width: 80%;
    margin: 0 auto;
}

.vcenter {
    display: flex;
    align-items: center;
}

.flex-40 {
    flex: 0 0 40% !important;
}

.option span {
    display: block;
    overflow: hidden;
}

.loader {
    width: 100vw;
    height: 100vh;
    position: relative;
    top: 0;
    left: 0;
    z-index: -1;
}

.loadergif {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -46px;
    margin-left: -69px;
    width: 138px;
    height: 92px;
}

.whitelabelLoaderImg {
    height: auto !important;
}

.loading-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 80px);
}

.btn.btn-skip {
    background: transparent;
    color: #f5755d;
    padding: 10px 12px;
    display: block;
    float: right;
}

.btn.btn-skip:hover {
    background: #f5755d;
    color: white;
}

.center-text {
    text-align: center;
}

.subtitle {
    font-size: 18px;
}

ul {
    list-style-type: square;
}

.btn-primary {
    background: #f4de57;
    color: #343a40;
    border-radius: 12px;
    border: 2px solid #f4de57;
}

.btn-primary:hover {
    background: #f5755d;
    border-color: #f5755d;
}

.btn-secondary {
    background: #f5755d;
    padding: 8px 40px;
    border-radius: 12px;
    border: 2px solid #f5755d;
}

.btn-default {
    background: none;
    border: 2px solid #f5755d;
    border-radius: 12px;
    color: #F5755D;
}

.btn-default:hover {
    background: #F5755D;
    color: white;
}

.jumbotron-fluid {
    padding-top: 30vh;
    height: 85vh;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/img/jumbo2.jpg");
    background-size: cover;
    background-position: center;
    text-align: center;
    color: #fff;
}

.jumbotron-fluid p.sub {
    max-width: 600px;
    margin: 0 auto;
}

.jumbotron-fluid ul {
    color: #f4de57;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}

.jumbotron-fluid li {
    padding-left: 1em;
    text-indent: -1em;
}

.jumbotron-fluid li:before {
    content: '\2713';
    padding-right: 5px;
}

.jumbotron-fluid-company-page-mtb {
    padding-top: 20vh;
    height: 55vh;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/img/Welke-mountainbike-past.jpg");
    background-size: cover;
    background-position: center;
    text-align: center;
    color: #fff;
}

.jumbotron-fluid-company-page-mtb-en {
    padding-top: 20vh;
    height: 55vh;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/img/What-mountain-bike-fits.jpg");
    background-size: cover;
    background-position: center;
    text-align: center;
    color: #fff;
}

.jumbotron-fluid-company-page-race {
    padding-top: 20vh;
    height: 55vh;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/img/Welke-racefiets-past.jpg");
    background-size: cover;
    background-position: center;
    text-align: center;
    color: #fff;
}

.jumbotron-fluid-company-page-race-en {
    padding-top: 20vh;
    height: 55vh;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/img/What-road-bike-fits.jpg");
    background-size: cover;
    background-position: center;
    text-align: center;
    color: #fff;
}

.jumbo-image {
    padding-top: 30vh;
    height: 85vh;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/img/Mountainbike-kopen-advies.jpg");
    background-size: cover;
    background-position: center;
    text-align: center;
    color: #fff;
}

.jumbo-image-en {
    padding-top: 30vh;
    height: 85vh;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/img/Mountain-bike-buying-advice.jpg");
    background-size: cover;
    background-position: center;
    text-align: center;
    color: #fff;
}

.btn-default.to_info {
    margin-left: 20px;
}

.btn.focus,
.btn:focus {
    box-shadow: none;
}

.table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

/*** ADVICE ***/
form.row {
    padding: 0 25px;
}

.option .option-inner:hover,
.optionr .option-inner:hover {
    background: #f7edb2;
}

/* Experience */
.title {
    margin-bottom: 40px;
}

.experience .option {
    padding: 20px;
}

.experience .option span {
    height: 50px;
}

.experience .option-4 {
    margin-left: 16.6666%;
}

.option .option-inner,
.optionr .option-inner {
    -webkit-box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 8px;
    transition: background 1s;
    position: relative;
    border: 1px solid #d4d4d4;
}

.option .fas {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #838383;
    z-index: 9;
}

.progress-bar {
    background: #f4de57;
}

.progress-icon img {
    position: relative;
}

/* Parcourtype */
.parcourtype>.option>.row {
    min-height: 200px;
    height: 100%;
}

.horizontal-options .option,
.parcourtype .option,
.usage .option,
.speed .option,
.beforeSizes .option {
    -webkit-box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #d4d4d4;
    border-bottom: 0px;
}

.parcourtype>.option-forpdf>.row {
    min-height: 200px;
    height: 100%;
}

.horizontal-options .option:hover,
.parcourtype .option:hover,
.usage .option:hover,
.speed .option:hover {
    background: #f7edb2;
}

.horizontal-options .option.no-effect:hover,
.parcourtype .option.no-effect:hover,
.usage .option.no-effect:hover,
.speed .option.no-effect:hover {
    background: none;
}

.horizontal-options .col-sm-7,
.parcourtype .col-sm-7,
.usage .col-sm-7,
.speed .col-sm-7,
.beforeSizes .col-sm-7 {
    background-size: cover !important;
    background-position: center !important;
}

.horizontal-options .col-sm-4,
.horizontal-options .col-sm-1,
.parcourtype .col-sm-4,
.parcourtype .col-sm-1,
.speed .col-sm-4,
.speed .col-sm-1,
.usage .col-sm-4,
.usage .col-sm-1 {
    padding: 20px;
}

.more-info {
    position: fixed;
    bottom: 0;
    background: #fff;
    z-index: 99;
    width: 65%;
    left: -3000px;
    padding: 20px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
    height: 100vh;
    margin-top: -104px;
    overflow: auto;
    transition: left .6s;
}

.show .more-info {
    left: 0;
}

.show .info-overlay {
    display: block;
}

.more-info.result {
    margin-top: -83px;
}

.more-info-container {
    padding: 60px;
}

.more-info img {
    margin-bottom: 25px;
    max-width: 100%;
}

.info-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.66);
    display: none;
}

.more-info .fas {
    float: right;
    width: 40px;
    height: 40px;
    background: #f5755d;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
}

/* Usage */
.horizontal-options .row,
.usage .row,
.speed .row,
.beforeSizes .row {
    min-height: 200px;
    height: 100%;
}

/* Speed */
.speed .option-1 .progress-bar {
    width: 100%;
}

.speed .option-2 .progress-bar {
    width: 66.66%;
}

.speed .option-3 .progress-bar {
    width: 33.33%;
}

/* speed */
video {
    max-width: 100%;
    width: 640px;
}

/* supsension */
.suspension .text-center {
    margin-bottom: 10px;
}

.suspension .option .info {
    margin-bottom: 10px;
}

.suspension .option span {
    height: 50px;
}

.suspension .option .infoBig span {
    height: 75px;
}

.popup-container {
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
}

.popup-container .popup {
    background: #fff;
    padding: 30px;
    width: 60%;
    margin: 100px auto 0;
    text-align: center;
    border-radius: 12px;
}

.popup-container .popup .btn {
    min-width: 200px;
    padding: 8px 20px;
    border-radius: 12px;
}

.popup-container .popup .btn-secondary {
    margin-right: 20px;
}

/* Gender */
.gender .option-1 {
    margin-left: 16.6666%;
}

.gender .input-group {
    width: 190px;
    margin: 0 auto;
}

.gender .option .age {
    width: 33.33333%;
    margin: 0 auto;
    -webkit-box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 8px;
    transition: background 1s;
    position: relative;
}

/* sizes */
.beforeSizes .option:hover {
    background: #f7edb2;
    transition: background 1s;
}

.sizes .form-buttons {
    margin-top: 40px;
}

.sizes .size {
    margin-bottom: 25px;
}

.sizes .input-group {
    width: 190px;
    margin: 0 auto;
}

.sizes .text-left {
    width: 300px;
    margin: 0 auto;
}

.sizes .sizes-img {
    max-height: 315px;
}

.margin-bottom {
    margin-bottom: 15px;
}

.shadow-column {
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
    border: 1px solid #d4d4d4;
    padding: 15px;
    border-radius: 8px;
    height: 100%;
    flex: 1;
    margin-bottom: 30px;
}

.shadow-column-without-flex {
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
    border: 1px solid #d4d4d4;
    padding: 15px;
    border-radius: 8px;
    height: 100%;
    margin-bottom: 30px;
}

.shadow-column:last-child {
    margin-bottom: 0;
}

.sizes.mtb .mtbLabel,
.sizes.race .raceLabel {
    width: 25%;
    float: left;
    line-height: 40px;
}

.sizes.mtb .radio-container,
.sizes.race .radio-container {
    display: inline-block;
    margin-left: 20px;
}

.sizes.mtb .radio-container input,
.sizes.race .radio-container input {
    margin: 10px;

}

.sizes.mtb .radio-container label .sizes.race .radio-container label {
    line-height: 40px;
}

.sizes.mtb .input-group,
.sizes.race .input-group {
    width: 75%;
}

.info-mtb-sizes {
    margin: 15px 0;
    padding: 15px;
    border: 4px solid #f4de57;
}

/* Price */
.theSlider .sliderBar {
    width: 100%;
    background: red;
    position: relative;
    height: 20px;
}

.theSlider .sliderBullet {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: yellow;
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -10px;

}

.slidecontainer {
    width: 100%;
    /* Width of the outside container */
}

.slidecontainer #price-slider {
    width: 100%;
}

input[type=range].slider {
    -webkit-appearance: none;
    width: 100%;
    margin: 0px 0;
}

input[type=range].slider:focus {
    outline: none;
}


input[type=range].slider::-webkit-slider-runnable-track {
    width: 100%;
    height: 22px;
    cursor: pointer;
    box-shadow: 0.1px 0.1px 0px #005900, 0px 0px 0.1px #007200;
    background: #f4de57;
    border-radius: 0px;
    border: 0px solid #010101;
}

input[type=range].round-border::-webkit-slider-runnable-track {
    border-radius: 15px;
}

input[type=range].slider::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px rgba(0, 0, 62, 0.67), 0px 0px 0px rgba(0, 0, 88, 0.67);
    border: 0.4px solid rgba(0, 30, 0, 0.57);
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: #f5755d;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;
}

input[type=range].slider::-moz-range-track {
    width: 100%;
    height: 22px;
    cursor: pointer;
    box-shadow: 0.1px 0.1px 0px #005900, 0px 0px 0.1px #007200;
    background: #f4de57;
    border-radius: 0px;
}

input[type=range].slider::-moz-range-thumb {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: #f5755d;
    cursor: pointer;
}

input[type=range].slider::-ms-track {
    width: 100%;
    height: 22px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type=range].slider::-ms-fill-lower {
    background: #f4de57;
    border: 0px solid #010101;
    border-radius: 0px;
    box-shadow: 0.1px 0.1px 0px #005900, 0px 0px 0.1px #007200;
}

input[type=range].slider::-ms-fill-upper {
    background: #f4de57;
    border: 0px solid #010101;
    border-radius: 0px;
    box-shadow: 0.1px 0.1px 0px #005900, 0px 0px 0.1px #007200;
}

input[type=range].slider::-ms-thumb {
    box-shadow: 0px 0px 0px rgba(0, 0, 62, 0.67), 0px 0px 0px rgba(0, 0, 88, 0.67);
    border: 0.4px solid rgba(0, 30, 0, 0.57);
    width: 22px;
    border-radius: 50%;
    background: #f5755d;
    cursor: pointer;
    height: 22px;
}

input[type=range].slider:focus::-ms-fill-lower {
    background: #f5755d;
}

input[type=range].slider:focus::-ms-fill-upper {
    background: #f5755d;
}

#myRange-slider {
    width: 70%;
    margin: 0 auto !important;
}

#demo {
    background: #ececec;
    display: inline-block;
    margin-bottom: 10px;
    padding: 10px;
}

#demo-2 {
    background: #f4de57;
    display: inline-block;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
}

.price .well {
    padding: 40px;
    margin-top: 30px;
    margin-bottom: 50px;
    border: 4px solid #f4de57;
}

.price .well strong {
    color: #f5755d;
}

.price .slider>.track {
    height: 10px !important;
    margin-top: -11px !important;
    background: #ececec;
}

.price .slider>.dragger {
    margin-top: -16px !important;
    background: #2a2a2a !important;
    box-shadow: none !important;
    border: none !important;
    height: 22px !important;
    width: 22px !important;
}

.optionr.option-0 .option-inner {
    background: #f4de57;
}

.optionr.option-0 .top-wrap {
    -webkit-box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
    margin: 0 -20px;
}

/*** Progress Bar ***/

.progress-container {
    width: 600px;
    margin: 0 auto 100px auto;
}

.progress-container-2 {
    width: 630px;
    margin: 100px auto 100px auto;
}


.progressbar {
    counter-reset: step;
}

.progressbar:after {
    content: "";
    border: 1px solid #878787;
    width: 70%;
    display: block;
    position: absolute;
    top: 15px;
    right: 60px;
    z-index: -10;
}

.progressbar li {
    list-style-type: none;
    width: 20%;
    float: left;
    font-size: 12px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: #7d7d7d;
}

.progressbar li:before {
    width: 30px;
    height: 30px;
    content: counter(step);
    counter-increment: step;
    line-height: 30px;
    border: 2px solid #7d7d7d;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: white;
}

.progressbar li:after {
    width: 100%;
    height: 2px;
    content: '';
    position: absolute;
    background-color: #7d7d7d;
    top: 15px;
    left: -50%;
    z-index: -1;
}

.progressbar li:first-child:after {
    content: none;
}

.progressbar li.active {
    color: #000;

}

.progressbar li.active:before {
    border-color: #f4de57;
    background-color: #f4de57;
}

.progressbar li.active+li:after {
    background-color: #f4de57;
}

.progressbar li.active.parcour+li:after {
    width: 25%;
}

.progressbar li.active.usage+li:after {
    width: 50%;
}

.progressbar li.active.speed+li:after {
    width: 75%;
}

.progressbar li.active.gender+li:after {
    width: 50%;
}

/*** Info Pages ***/
.info-page {
    margin-top: 18px;
}

.info-page h3 {
    margin-top: 30px;
    margin-bottom: 0px;
}

.info-page img {
    margin: 15px 0;
}

.info-page .startAdvice {
    position: fixed;
    right: 50px;
    bottom: 100px;
}

/*** FAQ ***/
.question-container {
    border: 2px solid #f4de57;
    padding: 15px 15px 0;
    margin-top: 15px;
}

.question {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}

.answer {
    display: none;
}

.answer.show {
    display: block;
}

/*** Contact ***/
.contact-form.testride {
    margin-top: 0;
}

.contact-form.testride form {
    padding: 5% 14%;
}

.contact-form .textfield {
    width: 100%;
    height: 150px;
}

.contact-form .btnContact {
    width: 50%;
    border: none;
    border-radius: 1rem;
    padding: 8px 40px;
    background: #f5755d;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
}

/*** Footer ***/

.footer {
    margin-top: 60px;
    padding: 60px 0 20px;
    background: #343a40;
    color: #fff;
}

.footer ul {
    list-style: none;
    padding: 0;
}

.footer a {
    color: #fff;
}

.footer a:hover {
    color: #fff;
    text-decoration: none;
}

#footer-sub-ul li:before {
    content: "-";
    margin-right: 1rem;
}


.select-header {
    background: #343940;
    border-color: #343940;
    margin-top: 2%;
    text-transform: uppercase;
    cursor: pointer;
    box-shadow: none;
    outline: none;
}

.video-playback {
    display: flex;
    cursor: pointer;
}

.video-playback>button {
    cursor: pointer;
}


.section-new-race,
.section-new-race-en,
.section-new-mtb,
.section-new-mtb-en {
    padding-top: 7vh;
    padding-bottom: 7vh;
    background-size: cover !important;
    text-align: center;
    color: #fff;
}

.section-new-race {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/img/Welke-mountainbike.jpg");
    background-position: center 64% !important;
}

.section-new-race-en {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/img/What-mountain-bike.jpg");
    background-position: center 64% !important;
}

.section-new-mtb {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/img/Welke-racefiets.jpg");
    background-position: center !important;
}

.section-new-mtb-en {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/img/What-road-bike.jpg");
    background-position: center !important;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #f4de57;
}

.dropdown-menu:last-child {
    left: -5em;
}

.blog-page.container {
    max-width: 960px;
}

.main {
    overflow-x: hidden;
}

.warning {
    color: #f5755d;
}

.userNavButton {
    color: white !important;
}

/*** Responsive ***/

@media screen and (min-width: 1200px) {

    .simplified-checkout-wrapper {
        min-height: 90vh;
    }

    .checkout-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 0 !important;
    }
}

@media screen and (max-width: 1200px) {}

@media screen and (max-width: 1025px) {
    .section-new-race {
        padding-top: 3vh;
        padding-bottom: 3vh;
    }

    .section-new-mtb {
        padding-top: 3vh;
        padding-bottom: 3vh;
    }
}

@media screen and (max-width: 768px) {
    body {
        font-size: 14px;
    }

    h3,
    .h3 {
        font-size: 18px;
        font-weight: bold;
    }

    .btn {
        font-size: 14px;
    }

    .parcourtype input[type=radio],
    .usage input[type=radio],
    .speed input[type=radio] {
        margin: 0;
    }

    input[type="radio"] {
        margin: 0;
    }

    /* advice */
    .jumbotron-fluid {
        padding-top: 15vh;
    }

    .progressbar {
        margin-left: -40px;
    }

    .progressbar:after {
        width: 72%;
        right: 60px;
    }

    .progressbar li {
        font-size: 8px;
    }

    .select-header {
        background: #343940;
        border-color: #343940;
        margin-top: 2%;
        text-transform: uppercase;
        cursor: pointer;
        box-shadow: none;
        outline: none;
    }

    .video-playback {
        display: flex;
        cursor: pointer;
    }

    .video-playback>button {
        cursor: pointer;
    }

    .dropdown-item.active,
    .dropdown-item:active {
        color: #fff;
        text-decoration: none;
        background-color: #f4de57;
    }

    .progressbar li:before {
        font-size: 12px;
    }

    .back.btn-link {
        font-size: 12px;
    }

    #skipbtn {
        margin-bottom: 10px;
        padding: 10px 15px;
    }

    #submitbtn {
        white-space: initial;
        padding: 10px 15px;
        margin-bottom: 10px;
    }

    .sub-title {
        width: 100%;
    }

    .horizontal-options .col-sm-1,
    .parcourtype .col-sm-1,
    .speed .col-sm-1,
    .usage .col-sm-1 {
        padding: 0px;
    }

    .horizontal-options .row,
    .parcourtype>.option>.row,
    .usage .row,
    .speed .row,
    .beforeSizes .row {
        min-height: 150px;
    }

    /* experience */
    .experience .option span {
        height: 60px;
    }

    /* Parcour Type */
    .more-info {
        width: 100%;
        padding: 5px;
        top: 190px;
        z-index: 99999
    }

    .more-info-container {
        padding: 10px;
        padding-bottom: 40px;
    }

    .horizontal-options .col-sm-7,
    .parcourtype .col-sm-7,
    .usage .col-sm-7,
    .speed .col-sm-7 {
        height: 150px;
    }

    .horizontal-options .col-sm-1 div,
    .parcourtype .col-sm-1 div,
    .usage .col-sm-1 div,
    .speed .col-sm-1 div {
        text-align: center;
        width: 100%;
    }

    /** suspension **/
    .popup-container .popup .btn {
        width: 100%;
        margin-bottom: 5px;
    }

    /** gender **/
    .gender .option .age {
        width: 100%;
    }

    .loader {
        max-width: 100%;
    }

    .option.prime .top-wrap {
        margin: 0 -4px;
    }

    /** contact **/
    .contact-form .btnContact {
        width: 100%;
    }

    .contact-form .btnContact {
        width: 50%;
        border: none;
        border-radius: 1rem;
        padding: 8px 40px;
        background: #f5755d;
        font-weight: 600;
        color: #fff;
        cursor: pointer;
    }

    .experience .option-4,
    .gender .option-1 {
        margin-left: 0;
    }

    .lead .btn {
        width: 45%;
    }

    .option .fas {
        position: absolute;
        width: 25px;
        text-align: center;
        top: 0;
        right: 0px;
        padding-top: 45%;
        height: 100%;
        color: #ffffff;
        font-size: 20px;
        background: #8a8a8a;
    }

    .more-info {
        top: 104px;
    }

    .section-new-race {
        padding-top: 10vh;
        padding-bottom: 10vh;
    }

    .section-new-mtb {
        padding-top: 10vh;
        padding-bottom: 10vh;
    }

}

@media screen and (max-width: 576px) {
    .beforeSizes .col-sm-7 {
        height: 150px;
    }

    .beforeSizes .radio-container {
        margin: 0 auto;
    }

    .section-new-race {
        padding-top: 3vh;
        padding-bottom: 3vh;
    }

    .section-new-mtb {
        padding-top: 3vh;
        padding-bottom: 3vh;
    }
}

@media screen and (max-width: 420px) {
    .order-12 {
        order: initial;
    }

    .popup-container .popup {
        width: 90%;
    }

    .popup-container .popup .btn {
        width: 100%;
        margin-bottom: 5px;
    }

    .gender .option .age {
        width: 100%;
    }

    .radio-container {
        width: 100%;
        text-align: center;
    }

    .form-page form,
    .contact-form.testride form {
        padding: 14% 0;
    }

    .horizontal-options input[type=radio],
    .experience input[type=radio],
    .gender input[type=radio],
    .parcourtype input[type=radio],
    .usage input[type=radio],
    .speed input[type=radio] {
        margin: 10px;
    }

    .section-new-race {
        padding-top: 3vh;
        padding-bottom: 3vh;
    }

    .section-new-mtb {
        padding-top: 3vh;
        padding-bottom: 3vh;
    }
}

.radio-box {
    margin-left: 40%;
    min-height: 0px !important;
}

.description {
    margin: 0 auto;
}

.divshow {
    display: none;
}

.space-20 {
    margin-top: 20px;
}

.start-button {
    background: #f5755d;
    padding: 8px 40px;
    border-radius: 12px;
    border: 2px solid #f5755d;

}

.start-button:hover {
    color: #fff !important;
    text-decoration: none !important
}

.parcourtype>.option>.row .more-info-link {
    color: #f5755d;
    cursor: pointer;
}

.checkout-container {
    max-width: 850px;
}

.checkout h1.header {
    margin-bottom: 23px;
}

.checkout-customer-msg {
    margin-top: 37px;
}

.checkout-container {
    max-width: 850px;
}

.h-100vh {
    height: 100vh;
}