    /*** HOMEPAGE ***/
    
    .home .nav-link.disabled {
        color: rgba(255, 255, 255, .25) !important;
    }
    
    .home .btn-primary {
        background-color: #fcbf1e;
        border-color: #fcbf1e;
        padding: 10px 30px;
        font-weight: 700;
    }
    
    .btn.btn-white {
        background-color: #fff;
        border-color: #fff;
        border-radius: 12px;
        color: #000;
        padding: 10px 30px;
        font-weight: 700;
    }
    
    .home h2 {
        font-weight: 700;
    }
    
    .home-hero {
        background: url('/img/home/online-bike-fit.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 500px;
        height: 70vh;
        background-position: 77% 58%;
        padding-top: 150px;
        color: white;
    }
    
    .home-hero h1 {
        font-weight: 700;
        margin-bottom: 15px;
        text-align: center;
    }
    
    .home-hero h1 span {
        color: #fcbf1e;
    }
    
    .home-hero p {
        text-align: center;
    }
    
    .home-hero .btn-primary {
        display: block;
        margin: 0 auto;
    }
    
    .home-hero ul {
        margin-bottom: 25px;
    }
    
    .home-hero li:before {
        content: '\2713';
        padding-right: 5px;
        color: #fcbf1e;
    }
    
    .home-bikefinder-promo {
        padding: 40px 30px;
        background: #f3f5f8;
        border-radius: 10px;
        position: relative;
        top: -40px;
    }
    
    .home-bikefinder-promo .bikefinder-promo-title {
        /*font-size: 21px;*/
        font-weight: 700;
        text-align: center;
    }
    
    .home-bikefinder-promo .bikefinder-promo-subtitle {
        color: #afafaf;
        /*font-weight: 700;*/
        text-align: center;
    }
    
    .home-bikefinder-promo .bikefinder-promo-block {
        height: 300px;
        border-radius: 10px;
        margin-top: 40px;
        color: white;
        position: relative;
        background-size: cover;
    }
    
    .home-bikefinder-promo .bikefinder-promo-block h3 {
        position: absolute;
        bottom: 70px;
        left: 25px;
        font-size: 32px;
    }
    
    .nl .home-bikefinder-promo .bikefinder-promo-mtb {
        /*background-image: url('/img/home/welke-mountainbike.jpg');*/
        background-image: url('/img/Welke-mountainbike-past.jpg');
    }
    
    .en .home-bikefinder-promo .bikefinder-promo-mtb {
        /*background-image: url('/img/home/what-mountain-bike.jpg');*/
        background-image: url('/img/What-mountain-bike-fits.jpg');
    }
    
    .nl .home-bikefinder-promo .bikefinder-promo-road {
        /*background-image: url('/img/home/welke-racefiets.jpg');*/
        background-image: url('/img/Welke-racefiets-past.jpg');
    }
    
    .en .home-bikefinder-promo .bikefinder-promo-road {
        /*background-image: url('/img/home/what-road-bike.jpg');*/
        background-image: url('/img/What-road-bike-fits.jpg');
    }
    
    .home-professional-reviews {
        margin-top: 30px;
    }
    
    .lead .btn-default {
        border-color: #f4de57;
        color: #f4de57;
    }
    
    .framefit_info {
        margin: 60px 0 60px;
    }
    
    .prime_title {
        text-align: center;
        margin-bottom: 20px;
    }
    
    .prime_title h2 {
        margin-bottom: 20px;
    }
    
    .framefit_info ul {
        display: inline-block;
        margin: 0 auto;
        text-align: left;
        width: auto;
    }
    
    .dark-section {
        background: #353d42;
        border-top: 8px solid #f4de57;
        border-bottom: 8px solid #f4de57;
        padding: 40px 0;
        color: #fff;
    }
    
    .dark-section h3 {
        color: #fff;
        margin: 15px 0;
        text-align: center;
    }
    
    .dark-section h3 i {
        float: right;
    }
    
    .lead .btn {
        width: 220px;
    }
    
    .home-column-inner {
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
        border-radius: 8px;
        padding: 15px;
    }
    
    .carousel.carousel-slider .slide {
        background: white;
    }
    
    .carousel.carousel-slider .slide div {
        top: 50%;
        transform: translateY(-50%);
        position: relative;
    }
    
    #slider {
        position: relative;
        overflow: hidden;
        margin: 20px auto 0 auto;
        border-radius: 4px;
    }
    
    #slider ul {
        position: relative;
        margin: 0;
        padding: 0;
        height: 200px;
        list-style: none;
    }
    
    #slider ul li {
        position: relative;
        display: block;
        float: left;
        margin: 0;
        padding: 0 15px;
        width: 250px;
        height: 300px;
        text-align: center;
        line-height: 300px;
    }
    
    .home-column-inner.slider {
        padding: 50px 0;
        margin: 15px 0;
    }
    
    .seo-intro-container {
        background: #faf0b1;
    }
    /*new*/
    
    .black-overlay {
        background-color: #26242444;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: inherit;
    }
    
    .home-bikefinder-promo .bikefinder-promo-block a {
        position: absolute;
        bottom: 25px;
        left: 25px;
    }
    
    .home-customer-reviews-title {
        color: #fcbf1e;
        /*font-weight: 700;*/
        font-size: 20px;
    }
    
    .home-expert-reviews {
        margin-top: 70px;
    }
    
    .our-services-heading {
        text-align: center;
    }
    
    .our-services-heading h5 {
        color: #fcbf1e;
        font-size: 20px;
    }
    
    .our-services-heading h2 {
        font-weight: 700;
    }
    
    .services-left {
        padding-right: 30px;
    }
    
    .services-left-heading {
        float: left;
        width: 100%;
        text-align: end;
    }
    
    .left-heading-content {
        float: right;
        width: 35%;
    }
    
    .left-heading-content img {
        width: 25px;
    }
    
    .left-heading-content h4 {
        display: inline;
        margin-left: 9px;
    }
    
    .left-heading-line {
        float: left;
        width: 63%;
    }
    
    .left-heading-line img {
        width: 100%;
    }
    
    .left-heading-subheading {
        float: right;
        width: 100%;
        color: #26242473;
    }
    
    .left-heading-subheading h5 {
        font-weight: 400;
    }
    
    .service {
        height: 260px;
    }
    
    .service .black-overlay {
        background-color: #262424bd;
    }
    
    .service-btn {
        float: right;
        text-align: end;
        width: 100%;
        z-index: 5;
        position: relative;
    }
    
    .service-content {
        z-index: 2;
        position: absolute;
        bottom: 10px;
        left: 20px;
        right: 20px;
    }
    
    hr.serviceshr {
        border-top: 1px solid #fff;
        margin: 8px 0;
    }
    
    .btn-services {
        color: #ffffff;
        background-color: transparent;
        border-color: #ffffff !important;
    }
    
    .service:hover button,
    .btn-services:hover {
        color: #fff;
        color: #fcbf1e;
        border-color: #fcbf1e !important;
    }
    
    .service {
        position: relative;
    }
    
    .service-1 {
        float: right;
        width: 80%;
        /*background: url('/img/home/dmitrii-vaccinium-sw9Vozf6j_4-unsplash.jpg');*/
        background: url('/img/home/welke-racefiets.jpg');
        background-position: center;
        padding: 20px;
        color: #fff;
        background-size: cover;
        border-radius: 10px;
    }
    
    .en .service-1 {
        background: url('/img/home/which-road-bike.jpg');
        background-position: center;
        background-size: cover;
    }
    
    .service-2 {
        float: right;
        width: 80%;
        /*background: url('/img/home/timotheus-frobel-puA67Y4ezIc-unsplash.jpg');*/
        background: url('/img/home/welke-mountainbike.jpg');
        background-position: center;
        padding: 20px;
        color: #fff;
        background-size: cover;
        border-radius: 10px;
    }
    
    .en .service-2 {
        background: url('/img/home/which-mountain-bike.jpg');
        background-position: center;
        background-size: cover;
    }
    
    .service-3 {
        float: right;
        width: 80%;
        /*background: url('/img/home/wolfram-bolte-V1vVp4uggy4-unsplash.jpg');*/
        background: url('/img/home/welke-e-bike.jpeg');
        /* background: url('/img/blog/Mountain Bike Types Overview.jpg'); */
        background-position: center;
        background-size: cover;
        padding: 20px;
        color: #fff;
        border-radius: 10px;
    }
    
    .en .service-3 {
        background: url('/img/home/which-e-bike.jpeg');
        background-position: center;
        background-size: cover;
    }
    
    .service-4 {
        float: right;
        width: 80%;
        background: url('/img/home/type-of-road-bikes.jpg');
        /* background: url('/img/blog/Mountain Bike Types Overview.jpg'); */
        background-position: center;
        padding: 20px;
        color: #fff;
        background-size: cover;
        border-radius: 10px;
    }
    
    .our-tools h4 {
        font-size: 24px;
    }
    
    .services-right {
        padding-left: 30px;
    }
    
    .services-right-heading {
        float: left;
        width: 100%;
        text-align: left;
    }
    
    .right-heading-content {
        float: left;
        width: 25%;
    }
    
    .right-heading-content img {
        width: 20%;
    }
    
    .right-heading-content h4 {
        display: inline;
        margin-left: 9px;
    }
    
    .right-heading-line {
        float: right;
        width: 73%;
    }
    
    .right-heading-line img {
        width: 100%;
    }
    
    .right-heading-subheading {
        float: left;
        width: 100%;
        color: #26242473;
    }
    
    .right-heading-subheading h5 {
        font-weight: 400;
    }
    
    .service-5 {
        float: left;
        width: 80%;
        background: url('/img/home/road-bike-fit.jpg');
        background-position: center;
        padding: 20px;
        color: #fff;
        background-size: cover;
        border-radius: 10px;
    }
    
    .service-6 {
        float: left;
        width: 80%;
        background: url('/img/home/kHHvFCQXSqLkYi4L7myA_2019_Aventon_Ultro_StarBlast_StudioPhotos_001_grande.jpg');
        /* background: url('/img/home/sportyBike.jpg'); */
        background-position: center;
        padding: 20px;
        color: #fff;
        background-size: cover;
        border-radius: 10px;
    }
    
    .service-7 {
        float: left;
        width: 80%;
        /* background: url('/img/home/cityBike.jpg'); */
        background: url('/img/home/asoggetti-JQGGf6OuIdQ-unsplash.jpg');
        background-position: center;
        padding: 20px;
        color: #fff;
        background-size: cover;
        border-radius: 10px;
    }
    
    .service-8 {
        float: left;
        width: 80%;
        /*background: url('/img/home/pexels-nataliya-vaitkevich-6863174.jpg');*/
        background: url('/img/home/bikefit-resultaten.jpg');
        background-position: center;
        padding: 20px;
        color: #fff;
        background-size: cover;
        border-radius: 10px;
    }
    
    .en .service-8 {
        background: url('/img/home/bike-fit-results.jpg');
        background-position: center;
        background-size: cover;
    }
    
    .our-services {
        background: url('/img/home/clark-young-7YMQpD5R7Uw-unsplash-cropped.jpg');
        text-align: center;
        color: #fff;
        margin-top: 6rem;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        padding: 100px 0px 100px 0px;
    }
    
    .our-services .card {
        border: none;
        border-bottom: 3px solid white;
        background-color: #fff;
        /*box-shadow: 0px 4px 8px 0px rgb(0 0 0 / 6%);*/
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
    }
    
    .card:hover {
        cursor: pointer;
        border-bottom: 3px solid #fcbf1e;
    }
    
    .card-body {
        color: #000;
    }
    
    .video-section {
        width: 100%;
        margin-top: 170px;
        background: url('/img/home/Grove-RAD-Thunderbolts-Adventure-06951web3.jpg');
        background-position: bottom;
        background-size: cover;
        background-repeat: no-repeat;
    }
    
    .video-section .container {
        position: relative;
    }
    
    .video-section-content {
        color: #fff;
        padding-top: 0px;
    }
    
    .our-services .card-img-top {
        height: 225px;
        width: 100%;
        object-fit: contain;
        padding: 1.5rem 2.5em 0rem;
        display: flex;
        align-items: center;
    }
    
    .our-services .card-img-top .home-column-inner {
        box-shadow: none;
    }
    
    .home .footer {
        margin-top: 0px;
    }
    
    .br-10 {
        border-radius: 10px;
    }
    
    .videoPoster {
        cursor: pointer;
    }
    
    .home-video-img-div {
        position: absolute;
        color: #fff;
        top: -170px;
    }
    
    .home-video-img-div-title {
        position: absolute;
        top: 15%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
    }
    
    .home-video-img-div-desc {
        position: absolute;
        bottom: 15%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        text-align: center;
    }
    
    .home h6 {
        font-weight: 600;
        font-size: 20px;
    }
    
    .video-section-content p {
        font-size: 0.72rem;
    }
    
    .home-bikefinder-promo .btn.btn-white:hover {
        background: #fcbf1e;
        border-color: #fcbf1e;
    }
    
    .home .our-tools {
        background: #f3f5f85e;
        padding: 5rem 0rem;
    }
    
    .home .our-tools-mobile {
        background: #f3f5f85e;
        padding: 3rem 0rem;
    }
    
    @media only screen and (max-width: 768px) {
        .services-left {
            padding: 0px;
        }
        .services-left-heading {
            text-align: center;
        }
        .left-heading-content {
            float: none;
            width: 100%;
        }
        .left-heading-line {
            float: none;
            width: 100%;
        }
        .left-heading-content img {
            width: 7%;
        }
        .service-1,
        .service-2,
        .service-3,
        .service-4,
        .service-5,
        .service-6,
        .service-7,
        .service-8 {
            float: left;
            width: 100%;
            min-height: 260px;
        }
        .services-right {
            padding: 0px;
        }
        .services-right-heading {
            text-align: center;
            margin-top: 3rem !important;
        }
        .right-heading-content {
            width: 100%;
        }
        .right-heading-line {
            width: 100%;
            float: left;
        }
        .right-heading-content img {
            width: 7%;
        }
        .our-services {
            /*background: url(/img/home/clark-young-7YMQpD5R7Uw-unsplash-cropped-mobile.jpg) #160b19;*/
            background: url('/img/home/clark-young-7YMQpD5R7Uw-unsplash-cropped-mobile.jpg');
            background-position: top;
            background-size: contain;
            background-repeat: no-repeat;
            padding: 80px 0px 0px 0px;
        }
        .our-services .card {
            width: 80% !important;
            margin-left: auto;
            margin-right: auto;
        }
        /* #card-3 { */
        /*margin-right: 1.5rem;*/
        /* } */
        .video-section {
            background: url('/img/home/Grove-RAD-Thunderbolts-Adventure-06951web2.jpg');
            margin-top: 100px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
            padding-top: 130px;
        }
        .home-video-img-div {
            position: absolute;
            color: #fff;
            bottom: 81%;
            width: 80%;
            height: auto;
            left: 50%;
            transform: translateX(-50%);
        }
        .video-section-content p {
            text-align: center;
        }
        .home-video-img-div-title h2 {
            font-size: calc(0.9rem + 0.7vw) !important;
        }
        .home-video-img-div-desc {
            font-size: calc(0.9rem + 0.5vw) !important;
            text-align: center;
            width: 80%;
        }
        .bikefinder-promo-block {
            width: 100%;
            height: 217px !important;
        }
        .home-bikefinder-promo .bikefinder-promo-block a {
            position: absolute;
            bottom: unset;
            left: 50%;
            top: 70%;
            transform: translate(-50%, -40%);
        }
        .home-bikefinder-promo .bikefinder-promo-block h3 {
            position: absolute;
            bottom: unset;
            left: 50%;
            top: 35%;
            transform: translate(-50%, -35%);
        }
        .home .our-tools {
            display: none !important;
        }
        .our-services .card-text {
            font-size: 18px;
        }
        .our-services .card-img-top {
            height: auto;
        }
    }
    /*new*/
    
    @media screen and (max-width: 420px) {
        .home-row {
            margin-bottom: 20px;
        }
    }
    
    @media screen and (max-width: 768px) {
        .display-4 {
            font-size: 26px;
            font-weight: 300;
            line-height: 26px;
        }
        .jumbotron-fluid p.sub {
            font-size: 18px;
            line-height: 18px;
        }
        .video-section {
            padding: 0;
        }
        .home-video-img-div {
            position: relative;
            bottom: initial;
            width: 100%;
            top: -30px;
        }
    }
    
    @media screen and (min-width: 768px) {
        .home-hero {
            background-position: 100% 50%;
            min-height: 700px;
            height: 80vh;
            padding-top: 250px;
        }
        .home-hero h1,
        .home-hero p {
            text-align: left;
        }
        .home-hero .btn-primary {
            margin: 0;
        }
        .home-bikefinder-promo .bikefinder-promo-block {
            height: 300px;
        }
        .our-services .card {
            width: 30%;
        }
        .video-section-content {
            color: #fff;
            padding-top: 65px;
        }
        /* .home .our-tools-mobile {
        display: none !important;
    } */
    }
    
    @media screen and (min-width: 769px) {
        .home .our-tools-mobile {
            display: none !important;
        }
    }
    
    @media screen and (min-width: 768px) and (max-width: 1024px) {
        .our-services .card-img-top {
            height: auto;
        }
        .our-services .card-text {
            font-size: 18px;
        }
        .our-services .card:first-child {
            /*margin-top: 225px!important;*/
            margin-top: 25px !important;
        }
        .our-services .card {
            margin: 20px auto !important;
        }
    }
    
    @media screen and (min-width: 1024px) {
        .video-section-content {
            padding-top: 160px;
        }
    }
    
    @media screen and (min-width: 1200px) {
        .our-services .card {
            width: 357px;
        }
        .video-section-content {
            padding-top: 250px;
        }
    }
    
    @media screen and (min-width: 320px) and (max-width: 320px) {
        .service-1,
        .service-2,
        .service-3,
        .service-4,
        .service-5,
        .service-6,
        .service-7,
        .service-8 {
            min-height: 280px;
        }
    }