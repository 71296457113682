body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
input[type="radio"].form-check-input {
  margin: 0px;
  margin-right: 20px;
}

.feedback-footer .subscription-price .old-price {
  font-size: 20px;
}


.feedback-footer .subscription-price .new-price {
  font-size: 25px;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .feedback-footer .subscription-price {
    padding: .10rem !important;
    font-size: small;
  }
  .feedback-footer .subscription-price .old-price {
    font-size: 10px;
  }

  .feedback-footer .subscription-price .new-price {
    font-size: 13px;
  }

  .feedback-footer .btn {
    font-size: 11px;
    padding: 4px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}