.card {
  width: 25rem;
  margin: 5px;
  min-height: 12rem;
  border-radius: 10px;
  border: 0;
}

.shadow-column {
  padding: 10px !important;
}

.card__body {
  padding: 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.card__text {
  text-align: left;
}

.card__stars {
  text-align: center;
}

.card__author {
  text-align: center;
}

.carousel-indicators {
  margin: 0;
  z-index: 0;
  right: none;
}

.star-ratting-img{
  width: 50% !important;
}

@media (max-width: 440px) {
  .card {
    width: 22rem;
    margin: 5px;
    min-height: 11rem;
  }
}
