.expertcard {
  min-height: 44rem;
  width: 95%;
}

.expertcard__body {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 0.6rem !important;
}

.expertcard__content {
  text-align: left;
}

@media (max-width: 575px) {
  .expertcard {
    min-height: 47rem;
    width: 95%;
  }
}

@media (max-width: 513px) {
  .expertcard {
    min-height: 38rem;
    width: 95%;
  }
}