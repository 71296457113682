.downloadGuideParent {
    cursor: pointer;
    right: 10%;
}

.font-20px {
    font-size: 20px;
}
/* Super small devices (phones) */
@media (max-width: 299.98px) {

    .heroCarouselWrapper {
        height: 75vh !important;
    }

    .heroTitleDescription h1 {
        font-size: 24px;
    }

    .heroTitleDescription p {
        font-size: 16px !important;
    }

    .heroTitleDescription {
        /* top: 65% !important; */
        /* transform: translateY(-80%) !important; */
        position: relative;
    }

    .BikeFittingButtonWrapper {
        top: 44% !important;
    }

    .custom-button {
        padding: 6px 10px;
    }

    .counterWrapper p {
        font-size: 14px !important;
    }

    .ratingWrapper {
        /* transform: translateY(-52%); */
        top: 65% !important;
        position: absolute;
    }

    .ratingWrapper img {
        width: 120px !important;
    }

    .trustpilot-wrapper {
        top: 76% !important;
    }

    .downloadGuideParent {
        right: unset !important;
        /* left: 10%; */
        bottom: 4% !important;
        top: unset !important;
        position: absolute;
    }

    .downloadGuideParent>div {
        padding: 9px !important;
        margin-right: 9px !important;
    }

    .downloadGuideParent>div>div {
        width: 34px !important;
        height: 34px !important;
        top: -26px !important;
    }


}

/* Extra small devices (phones) */
@media (min-width: 300px) and (max-width: 575.98px) {

    /* @media (max-width: 575.98px) { */
    .heroCarouselWrapper {
        height: 75vh !important;
    }

    .heroTitleDescription h1 {
        font-size: 29px;
    }

    .heroTitleDescription p {
        font-size: 20px !important;
    }

    .heroTitleDescription {
        /* top: 65% !important; */
        /* transform: translateY(-80%) !important; */
        /* position: relative; */
    }

    .BikeFittingButtonWrapper {
        top: 44% !important;
    }

    .counterWrapper p {
        font-size: 14px !important;
    }

    .ratingWrapper {
        /* transform: translateY(-52%); */
        top: 65% !important;
        position: absolute;
    }

    .ratingWrapper img {
        width: 140px !important;
    }

    .downloadGuideParent>div {
        padding: 9px !important;
    }

    .downloadGuideParent>div>div {
        width: 40px !important;
        height: 40px !important;
        top: -31px !important;
    }

    .downloadGuideParent {
        right: unset !important;
        /* left: 10%; */
        bottom: 4% !important;
        top: unset !important;
        position: absolute;
    }
}

/* Small devices (tablets) */
@media (min-width: 576px) and (max-width: 767.98px) {
    /* CSS rules for small devices */
}

/* Medium devices (desktops) */
@media (min-width: 768px) and (max-width: 991.98px) {
    /* CSS rules for medium devices */
}

/* Large devices (desktops) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    /* CSS rules for large devices */
}

/* Extra large devices (large desktops) */
@media (min-width: 1200px) {
    /* CSS rules for extra large devices */
}