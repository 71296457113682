.carousel-arrow{
    position: absolute;
    z-index: 99999;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    background-color: white;
    border: 0 !important;
    box-shadow: 0px 1px 10px 3px #00000022;
    outline: none !important;
}

.react-multi-carousel-list {
    position: unset !important;
}

.carousel-wrapper-div{
    position: relative;
}

@media screen and (min-width: 1025px) {
    .carousel-arrow.left.side{
        left: -20px;
    }
    .carousel-arrow.right.side{
        right: -20px;
    }
    .carousel-arrow.left.top{
        right: 70px;
        top: -65px;
    }
    .carousel-arrow.right.top{
        right: 0px;
        top: -65px;
    }
}
@media screen and (max-width: 1024px) {
    .carousel-arrow.left.side {
        left: -10px;
    }

    .carousel-arrow.right.side {
        right: -10px;
    }
    .carousel-arrow.left.top {
        right: 70px;
        top: -65px;
    }

    .carousel-arrow.right.top {
        right: 0px;
        top: -65px;
    }
}
@media screen and (max-width: 539px) {
    .carousel-arrow.left.top {
        left: -10px;
        top: unset;
    }
    
    .carousel-arrow.right.top {
        right: -10px;
        top: unset;
    }
}