.checkout .expertcard {
    min-height: 44rem;
    width: 100%;
    border-radius: 10px;
    border: 0;
    margin: 5px;
}

.checkout .expert-review-card-body {
    padding: 0.3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.checkout .expertcard__content {
    text-align: left;
}

.checkout .hr-with-img {
    position: relative;
}

.checkout .hr-with-img span {
    background-color: white;
    position: absolute;
    top: -13px;
    left: 7%;
    border-radius: 50%;
}

/* @media (max-width: 575px) {
  .expertcard {
    min-height: 47rem;
    width: 95%;
  }
}

@media (max-width: 513px) {
  .expertcard {
    min-height: 38rem;
    width: 95%;
  }
} */