.checkout .moreInfo.link {
    padding: 0;
}

.checkout.cursorPointer {
    cursor: pointer !important;
}

.checkout .btn-skip {
    margin-right: 10px;
}

.checkout .subscription-block {
    padding: 10px 0;
}

.checkout .subscription-block ul {
    padding-left: 17px;
}

.checkout .subscription-head-block, .checkout .subscription-pay-button {
    border-bottom: 1px solid #999;
    text-align: center;
}

.checkout .subscription-price {
    font-weight: 700;
    font-size: 20px;
}

.checkout .subscription-price .old-price {
    font-size: 16px;
    position: relative;
    color: white;
}

.checkout .subscription-price .price-label {
    color: #707171;
}

.checkout .subscription-price .old-price:before {
    position: absolute;
    content: "";
    left: 0;
    top: 47%;
    right: 0;
    border-top: 2px solid;
    border-color: #343a40;

    -webkit-transform: rotate(-15deg);
    -moz-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    -o-transform: rotate(-15deg);
    transform: rotate(-15deg);
}

.checkout .shadow-column.highlighted {
    background: #f7edb2;
}

.checkout .trustbox-subscribe{
    width: 160px;
}

.checkout .price-card {
    min-height: auto;
    border-radius: 10px;
    cursor: default !important;
}

.checkout .price-card:hover{
    border-bottom: 0;
}

.checkout .price-card > .row {
    min-height: 104px;
}

.checkout .price-card.highlighted {
    background-color: #f5755d;
    color: white;
}

.checkout .text-light-custom{
    color: #c2c4c5 !important
}

.checkout .btn-signup{
    width: 100%;
    border: 1px solid;
    font-size: 0.90rem;
    font-weight: 700;
    padding-top: 10px;
    padding-bottom: 10px;
    white-space: break-spaces;
    border-radius: 12px;
}

.checkout .text-orange{
    color: #f5755d;
}

.checkout .tabs-section {
    margin-top: 100px;
}

.checkout .scroll-down {
    color: #fff;
    font-size: 18px;
}

.checkout .nav-tabs{
    border-bottom: 0;
    overflow: hidden;
    background-color: #343a40;
    /* width: calc(100% + 6rem); */
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    /* width: 90%;
    margin: 0 auto; */
}

.checkout .nav-tabs .nav-item { 
    /* width: 19%; */
    font-size: 18px;
    font-weight: 700;
    background-color: #5b6065;
    border-radius: 10px 10px 0 0;
    margin: 0 5px;
    text-align: center;
    color: white;
}

.checkout .nav-tabs .nav-item .nav-link {
    padding: 1.25rem 0.5rem;
    /* font-size: 20px; */
    height: 100%;
}
.checkout .nav-link.active{
    border-radius: 10px 10px 0 0;
    color: black !important;
}
.checkout .nav-tabs .nav-item .nav-link.active:hover{
    border-color: #fff;
}
.checkout .nav-tabs .nav-item .nav-link:hover{
    border-color: #5b6065;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
}
.checkout .tab-content{
    padding: 3rem 6rem;
}

@media screen and (min-width: 1025px) {
    .checkout .shadow-column.highlighted {
        margin-top: -15px;
    }
    .checkout .nav-tabs .nav-item:first-child {
        margin-left: 10%;
    }

    .checkout .nav-tabs .nav-item:last-child {
        margin-right: 10%;
    }
}

@media screen and (max-width: 1024px) {
    .checkout .subscription-plans .subscription-plan {
        margin-top: 15px;
    }
    .checkout .tab-content {
        padding: 2rem 0.5rem;
    }
}
@media only screen and (max-width: 600px) {
    .checkout ul.nav.nav-tabs {
        display: block;
    }

    .checkout ul.nav.nav-tabs li.nav-item {
        border-radius: 0px !important;
    }

    .checkout .nav-tabs a.nav-link:after {
        content: '\25b4';
        float: right;
        padding-right: 5px;
        font-size: 32px;
        line-height: 32px;
    }
    .checkout .nav-tabs a.nav-link.active:after {
        content: '\25be';
    }
}